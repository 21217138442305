import axios from "axios";

import {
  Kit,
  KitDTO,
  LoginDTO,
  NoteDTO,
  PrivateNote,
  Report,
  ReportDTO,
  ResetDTO,
} from "./types";

const baseUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3000"
    : `https://api.${window.location.host}`;

export interface ReportStatsResult {
  reports: {
    count: number;
    latest: string;
  };
}

export interface VideoData {
  id: string;
  thumbnail_url_large: string;
  thumbnail_url_small: string;
  title: string;
}

export interface VideosResult {
  videos: VideoData[];
}

interface TokenResponse {
  accessToken: string;
}

const getAuthConfig = (accessToken: string) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export async function login(dto: LoginDTO) {
  const url = `${baseUrl}/login`;
  const { idToken, idp } = dto;
  const response = await axios.post<TokenResponse>(url, {
    idToken,
    idp,
  });
  return response;
}

export async function resetPassword(dto: ResetDTO) {
  const url = `${baseUrl}/reset`;
  const { id } = dto;
  const response = await axios.post(url, {
    id,
  });
  return response;
}

export async function deleteAccount(accessToken: any) {
  const url = `${baseUrl}/user`;
  const response = await axios.delete(url, getAuthConfig(accessToken));
  localStorage.clear();
  return response;
}

export async function deleteReport(reportId: string, accessToken: any) {
  const url = `${baseUrl}/report/${reportId}`;
  const response = await axios.delete(url, getAuthConfig(accessToken));
  return response;
}

export async function fetchReportStats() {
  const url = `${baseUrl}/stats`;
  const response = await axios.get<ReportStatsResult>(url);
  return response.data.reports;
}

export async function fetchVideos() {
  const url = `${baseUrl}/videos`;
  const response = await axios.get<VideosResult>(url);
  return response.data.videos;
}

export async function fetchReports(accessToken: string) {
  const url = `${baseUrl}/reports`;
  const response = await axios.get<Report[]>(url, getAuthConfig(accessToken));
  return response.data;
}

export async function createReport(report: ReportDTO, accessToken: string) {
  const url = `${baseUrl}/report`;
  const response = await axios.post<Report>(
    url,
    report,
    getAuthConfig(accessToken)
  );
  return response;
}

export async function createReportLite(report: ReportDTO) {
  const url = `${baseUrl}/reportlite`;
  const response = await axios.post<Report>(url, report);
  return response;
}

export async function createNote(
  note: NoteDTO,
  reportId: string,
  accessToken: string
) {
  const url = `${baseUrl}/note/${reportId}`;
  const response = await axios.post<PrivateNote>(
    url,
    note,
    getAuthConfig(accessToken)
  );
  return response;
}

export async function createKit(
  note: KitDTO,
  reportId: string,
  accessToken: string
) {
  const url = `${baseUrl}/kit/${reportId}`;
  const response = await axios.post<Kit>(url, note, getAuthConfig(accessToken));
  return response;
}
