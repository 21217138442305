import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import { useContext } from "react";
import { SizeContext } from "./App";
import { reportStatsContext } from "./ReportStatsProvider";
import PaddedBox from "./PaddedBox";
import TestimonyCarousel from "./TestimonyCarousel";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";

const subtitleStyle = { fontSize: "2.5rem !important" };

export default function LiveWidgetSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  const reportCount = useContext(reportStatsContext);
  const { count, latest } = reportCount;

  return (
    <PaddedBox orange sx={{ py: 8 }}>
      <Stack gap={8}>
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: large ? "9rem !important" : "4rem !important",
            }}
            color="common.white"
          >
            {count}{" "}
            <Box component="span" sx={{ fontSize: "3rem" }}>
              {count === 1 ? "report" : "reports"}
            </Box>
          </Typography>
          <Typography variant="body1" color="common.white" sx={subtitleStyle}>
            last report: {latest}
          </Typography>
        </Box>
        <TestimonyCarousel interval={10000} indicators={false} />
        <Box alignSelf="center">
          <ButtonLink theme="filled" href={Links.ReportAnonymous}>
            Share anonymously
          </ButtonLink>
        </Box>
      </Stack>
    </PaddedBox>
  );
}
