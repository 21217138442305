import React, { useState, useContext } from "react";
import { SizeContext } from "./App";
import { Box, Typography, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ReportFormSection from "./ReportFormSection";
import WhatNextSection from "./WhatNextSection";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";
import RecoveryStartsSection from "./RecoveryStartsSection";
import RecoverWithEnoughSection from "./RecoverWithEnoughSection";

enum PageMode {
  Menu,
  Lite,
  Full,
}

export default function ReportPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const anonymous = urlParams.get("anonymous");
  let initialMode;
  switch (anonymous) {
    case "true":
      initialMode = PageMode.Lite;
      break;
    case "false":
      initialMode = PageMode.Full;
      break;
    default:
      initialMode = PageMode.Menu;
  }

  const size = useContext(SizeContext);
  const large = size === "large";
  const [showMessage, setShowMessage] = useState(false);
  const [pageMode, setPageMode] = useState<PageMode>(initialMode);

  return (
    <PageContainer>
      {pageMode === PageMode.Menu && (
        <PaddedBox orange>
          <Stack
            direction={large ? "row" : "column"}
            justifyItems="center"
            justifyContent={large ? "space-evenly" : "center"}
            alignItems="center"
            gap={large ? 0 : "1em"}
          >
            <Box
              onClick={() => {
                setPageMode(PageMode.Lite);
              }}
              sx={{
                border: "solid 2px",
                borderRadius: "1em",
                padding: "1.5em",
                width: "14em",
                cursor: "pointer",
              }}
            >
              <Typography variant="h1">It happened a while ago</Typography>
            </Box>
            <Box
              onClick={() => {
                setPageMode(PageMode.Full);
              }}
              sx={{
                border: "solid 2px",
                borderRadius: "1em",
                padding: "1.5em",
                width: "14em",
                cursor: "pointer",
                ml: large ? 5 : 0,
              }}
            >
              <Typography variant="h1">It just happened</Typography>
            </Box>
          </Stack>
        </PaddedBox>
      )}
      {pageMode === PageMode.Full && (
        <>
          <PaddedBox sx={{ pb: 0 }}>
            <Grid2 container>
              <Grid2 display="flex" alignItems="center">
                <Box>
                  <Typography variant="h1">
                    What happened is not ok and was not your fault.
                  </Typography>
                  <br />
                  <Typography variant="h1">
                    Reporting helps validate what happened so you can begin to
                    move forwards.
                  </Typography>
                  <br />
                  <Typography variant="h1">
                    If you want to report to the police now, you should go there
                    first. You can come back here after.
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </PaddedBox>
          <PaddedBox orange>
            <Typography variant="h3">
              This report belongs to you and no one else. It will be kept in
              your encrypted account
            </Typography>
          </PaddedBox>
        </>
      )}
      {pageMode === PageMode.Lite && (
        <>
          <PaddedBox orange>
            <Typography variant="h3">
              “We should not be ashamed and we should speak if we want to”
            </Typography>
          </PaddedBox>
        </>
      )}

      {pageMode !== PageMode.Menu && (
        <ReportFormSection
          onSuccess={() => setShowMessage(true)}
          mode={pageMode === PageMode.Full ? "full" : "lite"}
        />
      )}
      {pageMode === PageMode.Lite && <RecoverWithEnoughSection />}
      {showMessage && <WhatNextSection />}
    </PageContainer>
  );
}
