import React from "react";
import { Links } from "../types";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";

import ButtonLink from "./ButtonLink";
import ThemedButton from "./ThemedButton";

export interface ReportSavedModalProps {
  open: boolean;
  handleClose: (e: any, reason: string) => void;
  liteMode: boolean;
}
export default function ReportSavedModal(props: ReportSavedModalProps) {
  const { open, handleClose, liteMode } = props;
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogContent sx={{ mb: 3 }}>
        <Box
          justifyContent="flex-end"
          display="flex"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          {!liteMode && (
            <DialogContent>
              <Typography variant="h5">
                You've just taken a very important step.
              </Typography>
            </DialogContent>
          )}
          <Link
            variant="button"
            component="button"
            sx={{ p: 2, fontSize: 30, textDecoration: "none" }}
            onClick={() => handleClose(null, "none")}
          >
            ×
          </Link>
        </Box>
        <DialogContent>
          {liteMode
            ? "Thank you. Every message makes a difference. Close this window to see our recovery tools."
            : " We are now going to show you some next steps that might be important to think about, and then introduce you to recovery at enough."}
        </DialogContent>
        <center>
          {liteMode ? (
            <ThemedButton
              theme="filled"
              onClick={() => {
                handleClose(null, "none");
              }}
            >
              Close
            </ThemedButton>
          ) : (
            <ButtonLink href={Links.Next} theme="filled">
              Next Steps
            </ButtonLink>
          )}
        </center>
      </DialogContent>
    </Dialog>
  );
}
