import * as React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import AboutPage from "./AboutPage";
import AccountPage from "./AccountPage";
import AlliesPage from "./AlliesPage";
import ApproachPage from "./ApproachPage";
import BristolPage from "./BristolPage";
import ContactPage from "./ContactPage";
import CrisisPage from "./CrisisPage";
import FAQPage from "./FAQPage";
import FundUsPage from "./FundUsPage";
import HomePage from "./HomePage";
import InstructionsPage from "./InstructionsPage";
import KitPage from "./KitPage";
import LivePage from "./LivePage";
import NextStepsPage from "./NextStepsPage";
import NotFoundPage from "./NotFoundPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import RecoveryPage from "./RecoveryPage";
import ReportPage from "./ReportPage";
import TermsPage from "./TermsPage";
import TherapyPage from "./TherapyPage";
import UnderstandingResultsPage from "./UnderstandingResultsPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomePage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="account" element={<AccountPage />} />
      <Route path="allies" element={<AlliesPage />} />
      <Route path="approach" element={<ApproachPage />} />
      <Route path="bristol" element={<BristolPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="crisis" element={<CrisisPage />} />
      <Route path="faqs" element={<FAQPage />} />
      <Route path="fund" element={<FundUsPage />} />
      <Route path="howto" element={<InstructionsPage />} />
      <Route path="kit" element={<KitPage />} />
      <Route path="live" element={<LivePage />} />
      <Route path="report" element={<ReportPage />} />
      <Route path="recovery" element={<RecoveryPage />} />
      <Route path="nextsteps" element={<NextStepsPage />} />
      <Route path="privacy" element={<PrivacyPolicyPage />} />
      <Route path="tandcs" element={<TermsPage />} />
      <Route path="therapy" element={<TherapyPage />} />
      <Route
        path="understanding-results"
        element={<UnderstandingResultsPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </>
  )
);

export default router;
