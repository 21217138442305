import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import PaddedBox from "./PaddedBox";
import { Box, Link, Typography } from "@mui/material";
import social from "../img/enough-social.png";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Links } from "../types";
import ButtonLink from "./ButtonLink";

export default function RecoverWithEnoughSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Typography variant="h1">Recover with enough</Typography>
      <br />
      <Grid2 container>
        <Grid2 xs={large ? 6 : 12}>
          <Typography variant="h3">Community</Typography>
          <Typography variant="body1">To help you feel less alone</Typography>
          <br />
          <Box>
            <Link href={Links.Social} target="_blank">
              <img
                src={social}
                style={{ width: "90%", border: "solid 1px black" }}
              />
            </Link>
          </Box>
        </Grid2>
        <Grid2 xs={large ? 6 : 12} sx={{ mt: large ? 0 : 5 }}>
          <Typography variant="h3">Videos</Typography>
          <Typography variant="body1">To help you understand</Typography>
          <br />

          <Box>
            <Link href={Links.Youtube} target="_blank">
              <img
                src="https://i.ytimg.com/vi/9VhekCMdOFQ/mqdefault.jpg"
                style={{ width: "90%" }}
              />
            </Link>
          </Box>
        </Grid2>
      </Grid2>
      <br />
      <ButtonLink href={Links.Recovery} theme="outlined">
        Learn more
      </ButtonLink>
    </PaddedBox>
  );
}
