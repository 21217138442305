import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Button, ButtonProps } from "@mui/material";
import { NavLink } from "react-router-dom";

interface ButtonLinkProps extends ButtonProps {
  theme: "outlined" | "filled" | "clear" | "color-outlined";
  href: string;
  target?: string;
}

export default function ButtonLink(props: ButtonLinkProps) {
  const { theme, target } = props;
  const size = useContext(SizeContext);
  const large = size === "large";
  const { children, href } = props;
  let backgroundColor: string;
  let color: string;
  let borderColor: string;
  if (theme === "outlined") {
    backgroundColor = "common.white";
    color = "common.black";
    borderColor = "common.black";
  } else if (theme === "filled") {
    backgroundColor = "secondary.main";
    color = "common.white";
    borderColor = "common.white";
  } else if (theme === "color-outlined") {
    backgroundColor = "common.white";
    color = "secondary.main";
    borderColor = "secondary.main";
  } else {
    backgroundColor = "common.white";
    color = "common.black";
    borderColor = "common.white";
  }
  return (
    <NavLink
      style={{ textDecoration: "none" }}
      to={href}
      target={target || "_self"}
    >
      <Button
        variant="outlined"
        sx={{
          "&:hover": {
            borderRadius: "2rem",
            color: backgroundColor,
            backgroundColor:
              color !== "common.black" ? color : "secondary.main",
          },
          fontSize: "1.5rem",
          textTransform: "unset",
          backgroundColor,
          color,
          borderColor,
          borderRadius: "1.5rem",
          px: large ? "3rem" : "1rem",
          py: large ? "1rem" : ".5rem",
          mb: large ? "0" : "1rem",
          ...props.sx,
        }}
      >
        {children}
      </Button>
    </NavLink>
  );
}
