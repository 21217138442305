import * as React from "react";
import { Link } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import insta1 from "../img/insta1.png";
import insta2 from "../img/insta2.png";
import insta3 from "../img/insta3.png";
import { Links } from "../types";

export default function InstagramSection() {
  return (
    <Link href={Links.Instagram} target="_blank">
      <Grid2
        container
        sx={{ maxWidth: "100%", mb: 0, p: 0 }}
        alignItems="flex-end"
      >
        <Grid2 xs={4} sx={{ px: "1px", py: 0, lineHeight: "20px" }}>
          <img src={insta1} style={{ width: "100%" }} />
        </Grid2>
        <Grid2 xs={4} sx={{ px: "1px", py: 0, lineHeight: "20px" }}>
          <img src={insta2} style={{ width: "100%" }} />
        </Grid2>
        <Grid2 xs={4} sx={{ px: "1px", py: 0, lineHeight: "20px" }}>
          <img src={insta3} style={{ width: "100%" }} />
        </Grid2>
      </Grid2>
    </Link>
  );
}
