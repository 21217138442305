import React from "react";
import PageContainer from "./PageContainer";
import PaddedBox from "./PaddedBox";
import Typography from "@mui/material/Typography";
import RecoverWithEnoughSection from "./RecoverWithEnoughSection";

export default function UnderstandingResultsPage() {
  return (
    <PageContainer gap={0}>
      <PaddedBox orange>
        <Typography variant="h3">
          Read this if you’d like help making sense of your results and choices.
        </Typography>
      </PaddedBox>
      <PaddedBox>
        <Typography variant="h1">What do the results mean?</Typography>
        <br />
        <Typography variant="body1">
          We confirm the number and gender of DNA samples found in or on your
          body. A ‘DNA profile’ refers to a person.
        </Typography>
        <br />
        <Typography variant="body1">
          If the results do not show the number of people or gender that you
          expected, it does not mean that someone else wasn’t there, it just
          means it didn’t show up with our swab.
        </Typography>
        <br />
        <Typography variant="h1">What happens now?</Typography>
        <br />
        <Typography variant="body1">
          Whatever you want. We will freeze half of the sample (untested) in
          case you want to report to the police later.
        </Typography>
        <br />
        <Typography variant="body1">
          If you’re considering reporting to the police, take your time,
          consider speaking to an ISVA at a Sexual Assault Referral Centre.
        </Typography>
      </PaddedBox>
      <RecoverWithEnoughSection />
    </PageContainer>
  );
}
