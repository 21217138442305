import React from "react";
import { useContext } from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { SizeContext } from "./App";
import { Links } from "../types";
import ButtonLink from "./ButtonLink";
import { Box } from "@mui/material";

export default function TestReportRecoverSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Grid2 container>
        <Grid2
          xs={large ? 4 : 12}
          sx={{ pr: 3, pb: large ? 0 : 6 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h3">Self Test</Typography>
            <Typography variant="body1">
              Simple and discreet DNA test. Results that belong to you and no
              one else.
            </Typography>
          </Box>
          <br />
          <ButtonLink theme="outlined" href={Links.Kit}>
            Get a kit
          </ButtonLink>
        </Grid2>
        <Grid2
          xs={large ? 4 : 12}
          sx={{ pr: 3, pb: large ? 0 : 6 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h3">Report</Typography>
            <Typography variant="body1">
              No questions asked. Put what happened into words and start to
              recover.
            </Typography>
          </Box>
          <br />
          <ButtonLink theme="outlined" href={Links.Report}>
            Report now
          </ButtonLink>
        </Grid2>
        <Grid2
          xs={large ? 4 : 12}
          sx={{ pr: 3, pb: large ? 0 : 6 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h3">Recover</Typography>
            <Typography variant="body1">
              Free and effective resources for you and your allies.
            </Typography>
          </Box>
          <br />
          <ButtonLink theme="outlined" href={Links.Recovery}>
            Recovery
          </ButtonLink>
        </Grid2>
      </Grid2>
      <br />
      <Typography variant="h5">
        DNA and a time-stamped report give survivors power and options
      </Typography>
    </PaddedBox>
  );
}
