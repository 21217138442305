import React, { useContext, useState } from "react";
import { Button } from "@mui/material";
import ReportList from "./ReportList";
import { reportsContext } from "./ReportsProvider";
import { authContext } from "./AuthProvider";
import { deleteAccount } from "../api";
import DeleteModal from "./DeleteModal";
import PageContainer from "./PageContainer";
import PaddedBox from "./PaddedBox";
import Typography from "@mui/material/Typography";
import RecoverWithEnoughSection from "./RecoverWithEnoughSection";

export default function AccountPage() {
  const { accessToken, setIdToken } = useContext(authContext);
  const { reports } = useContext(reportsContext);
  const [modalOpen, setModalOpen] = useState(false);
  const handleDeleteAccount = async () => {
    try {
      await deleteAccount(accessToken);
      setIdToken(null);
    } catch (e) {
      console.error("Problem deleting account");
    }
  };

  if (!accessToken) window.location.assign("/");
  return (
    <>
      <PageContainer>
        <PaddedBox orange>
          <Typography variant="h1">
            Your information, results and notes, all in one place
          </Typography>
        </PaddedBox>
        <ReportList reports={reports} />
        <RecoverWithEnoughSection />
        <PaddedBox>
          <Button
            color="error"
            sx={{ maxWidth: "10rem" }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Delete account
          </Button>
        </PaddedBox>
      </PageContainer>
      <DeleteModal
        open={modalOpen}
        title="Delete Account"
        description="Permanently delete your enough account and all associated data. This cannot be undone."
        dismissMessage="Keep account"
        deleteMessage="Delete account"
        handleDelete={handleDeleteAccount}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
}
